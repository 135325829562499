@import "../styles/variables.scss";

.wrapper {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: $line;
  transition: 0.1s;
  line-height: 22px;
  cursor: pointer;
  width: 22px;
  height: 22px;
  text-align: center;
  margin-left: 6px;
}
